import styled, { createGlobalStyle } from 'styled-components';

// Global styles
export const GlobalStyle = createGlobalStyle`
body{
  font-family: 'Raleway', sans-serif;
  font-size: 18px;
  font-weight: 200;
  line-height: 1.6;
  color: #fff;
  background-color: #222;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Raleway', sans-serif;
}

/* unvisited link */
a:link {
  color: red;
}
/* visited link */
a:visited {
  color: green;
}
/* mouse over link */
a:hover {
  color: hotpink;
}
/* selected link */
a:active {
  color: #34557d;
} 

section {
  position: relative;
}

.drawer-content-wrapper{
  @media (max-width: 767px) {
    width: 300px!important;
  }
  .drawer-content {
    padding: 60px;    
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @media (max-width: 767px) {
      padding: 50px 40px 30px 40px;
    }
    .mobile_menu {
      margin-bottom: 40px;
      flex-grow: 1;
      @media (max-width: 767px) {
        margin-bottom: 30px;
      }
      li{
        margin-bottom: 35px;
        @media (max-width: 767px) {
          margin-bottom: 25px;
        }
        a{
          font-size: 20px;
          font-weight: 400;
          color: #343d48;
          position: relative;
          transition: 0.15s ease-in-out;
          @media (max-width: 767px) {
            font-size: 18px;
          }
          &:hover {
            color: #ff4361;
          }
          &:before{
            content: '';
            width: 7px;
            height: 7px;
            background: #ff4361;
            border-radius: 50%;
            position: absolute;
            top: 50%;
            left: -15px;
            transform: translateY(-50%);
            opacity: 0;
          }
        }
        &.is-current {
          a {
            color: #ff4361;
            &:before{
              opacity: 1;
            }
          }
        }
      }
    }
    .navbar_drawer_button button{
      width: 100%;
    }
  }

  .reusecore-drawer__close{
    width: 34px;
    height: 34px;
    position: absolute;
    top: 20px;
    right: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    @media (max-width: 767px) {
      top: 15px;
      right: 15px;
    }
    &:before{
      content: '\f10b';
      font-family: Flaticon;
      font-size: 26px;
      color: #ff4361;
      transform: rotate(45deg);
      display: block;
    }
  }
}

.video-modal{
  background: transparent !important;
  border: 0!important;
  .innerRndComponent{
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
  }
}
.reuseModalOverlay,
.reuseModalParentWrapper{
  z-index: 99999!important;
}

`;

export const ContentWrapper = styled.div`
  overflow: hidden;
  .sticky-nav-active {
    .saas_navbar {
      background: #fff;
      box-shadow: 0px 3px 8px 0px rgba(43, 83, 135, 0.08);
      padding: 15px 0;
      .main-logo {
        display: none;
      }
      .logo-alt {
        display: block;
      }
      .main_menu {
        li {
          a {
            color: #000;
            &:hover {
              color: #ff4361;
            }
          }
          &.is-current {
            a {
              color: #ff4361;
            }
          }
        }
      }
    }
    .hamburgMenu__bar {
      > span {
        background: #ff4361;
      }
    }
  }

  .saas_navbar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    transition: 0.35s ease-in-out;
    padding: 30px 0;
    .logo-alt {
      display: none;
    }
    .main_menu {
      margin-right: 40px;
      li {
        display: inline-block;
        padding-left: 13px;
        padding-right: 13px;
        &:first-child {
          padding-left: 0;
        }
        &:last-child {
          padding-right: 0;
        }
        &.is-current {
          a {
            color: #edcd37;
          }
        }
        a {
          padding: 5px;
          font-size: 16px;
          font-weight: 400;
          color: #fff;
          transition: 0.15s ease-in-out;
          &:hover {
            color: #edcd37;
          }
        }
      }
      @media (max-width: 990px) {
        display: none;
      }
    }
    .navbar_button {
      @media (max-width: 990px) {
        display: none;
      }
    }
    .reusecore-drawer__handler {
      @media (min-width: 991px) {
        display: none !important;
      }
      .hamburgMenu__bar {
        > span {
        }
      }
    }
  }
`;
