// REACT AND GATSBY IMPORTS
import React from 'react';
// STYLING
// CONTAINERS
// import BannerSection from '../../containers/SaasClassic/Banner'
// COMPONENTS (landing-gatsby)
import UsersLayout from 'gatsby-landing/src/components/UsersLayout';
// COMMON COMPONENTS
import Job from 'common/src/components/Job';

// User interface for viewing a job listing
const UsersJobPage = () => {
  return (
    <UsersLayout seo={{ title: 'My Job' }}>
      <Job />
    </UsersLayout>
  );
};

export default UsersJobPage;
