// REACT AND GATSBY IMPORTS
import React, { Fragment, useState, useEffect, cloneElement } from 'react';
import { ThemeProvider } from 'styled-components';
import { Modal } from '@redq/reuse-modal';
// STYLING
import { ResetCSS } from 'common/src/assets/css/style';
import {
  GlobalStyle,
  ContentWrapper,
} from '../containers/SaasClassic/podstacksUsers.style';
import '@redq/reuse-modal/es/index.css';
import { saasClassicTheme } from 'common/src/theme/saasClassic';    // Should make my own at some point
import { row } from './default.styles'
// CONTAINERS
import Sticky from 'react-stickynode';
import Navbar from '../containers/SaasClassic/Navbar'
import UsersNavbar from '../containers/SaasClassic/UsersNavbar';
import { DrawerProvider } from 'common/src/contexts/DrawerContext';
// COMPONENTS
import Spacer from 'common/src/components/Spacer';
// import UsersMyJobsPage from 'gatsby-landing/src/pages/login'
import SEO from './seo';

// ELEMENTS
import Box from 'reusecore/src/elements/Box'
// import Newsletter from '../containers/SaasClassic/Newsletter';
// import Footer from '../containers/SaasClassic/Footer';
// LOGIC

import {
  login,
  logout,
  isLoggedIn,
  isLoggingOut,
  // getUser,
  // reqAuthToken,
  authTokenExists,
  isValidAuthToken
} from '../../../api/UserLoginAPI'

// User page layout template
const UsersLayout = ({children, seo, heading, isLoginPage = false}) => {
  const [ isInitialLogin, updateIsInitialLogin ] = useState( false )
  const [ isValidUser, updateIsValidUser ] = useState( false )

  // Check if user is logged in
  useEffect( () => {
    async function checkLoginStatus() {
      // On Log In page - Check for token and auth flag in url
      if( isLoginPage ) { 
        if ( authTokenExists() ){
          await login()
        } else {
          if ( isLoggingOut() )
            logout()
          else
            updateIsInitialLogin( true )
        }
      } else { // On any other protected page - Validate user
        if( isLoggedIn() && await isValidAuthToken() )
          updateIsValidUser( true )
        else
          logout()    // Redirects to /login page
      }
    }
    checkLoginStatus()      // Always perform on component load
  }, [])

  // console.log ({ isLoginPage, isInitialLogin, isValidUser })
  return (
    <ThemeProvider theme={saasClassicTheme}>
      <Fragment>
        <SEO {...seo}/>
        <Modal />
        <ResetCSS />
        <GlobalStyle />

        <ContentWrapper>
          {/* Conditionally display content */}
          <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
            <DrawerProvider>
              { ( !isLoginPage && isValidUser ) ?
              <><UsersNavbar /></>:
              <><Navbar /></>
              }
            </DrawerProvider>
          </Sticky>
          {/* // Spacer (kind of a hack...) */}
          <Spacer />

          { (heading) ?
          <Box {...row}>
            <h2>{heading}</h2>
          </Box>:<></>
          }

          {/* // Main content */}
          { ( (isLoginPage && isInitialLogin) || (!isLoginPage && isValidUser ) ) ? 
            children:<></>
          }


          {/* <Footer /> */}
        </ContentWrapper>
      </Fragment>
    </ThemeProvider>
  );
};

export default UsersLayout;
