import React, {Fragment} from 'react';
import SpacerWrapper from './spacer.style'

// Spacer Component
const Spacer = ({ className }) => {
  // Add all classs to an array
  const addAllClasses = ['spacer'];
  if (className)
    addAllClasses.push(className);
  return (
    <SpacerWrapper className={addAllClasses.join(' ')}/>
  )
}

export default Spacer;
