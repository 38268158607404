// REACT AND GATSBY
import React, { useContext } from 'react';
import { Link, useStaticQuery, graphql } from 'gatsby';
// import PropTypes from 'prop-types';

// ELEMENTS AND COMPONENTS
import NavbarWrapper from 'reusecore/src/elements/Navbar';
import Drawer from 'reusecore/src/elements/Drawer';
import Button from 'reusecore/src/elements/Button';
import Logo from 'reusecore/src/elements/UI/Logo';
import Heading from 'reusecore/src/elements/Heading';
import Box from 'reusecore/src/elements/Box';
import HamburgMenu from 'common/src/components/HamburgMenu';
import Container from 'common/src/components/UI/Container';
import { DrawerContext } from 'common/src/contexts/DrawerContext';
import ScrollSpyMenu from 'common/src/components/ScrollSpyMenu';

// STYLING
import { styles } from './userNavbar.styles'; // CSS styling

// LOGIC AND HANDLERS
// import { InputHandler } from './handlers'; // Code to handle user I/O

import LogoImage from 'common/src/assets/image/saasClassic/logo-white.png';
import LogoImageAlt from 'common/src/assets/image/saasClassic/logo.png';


// REACT AND GATSBY
// ELEMENTS AND COMPONENTS
// STYLING
// LOGIC AND HANDLERS
// DUMMY DATA
// APIS
// NPM MODULES



// const UsersNavbar = ({ navbarStyle, logoStyle, button, row, menuWrapper }) => {
const UsersNavbar = () => {
  const Data = useStaticQuery(graphql`
    query {
      saasClassicJson {
        USER_MENU_ITEMS {
          label
          path
          offset
        }
      }
    }
  `);

  const { navbarStyle, row, logoStyle, buttonStyle, menuWrapper} = styles
  const { state, dispatch } = useContext(DrawerContext);

  // Toggle drawer
  const toggleHandler = () => {
    dispatch({
      type: 'TOGGLE',
    });
  };

  return (
    <NavbarWrapper {...navbarStyle} className="saas_navbar">
      <Container>
        <Box {...row}>
          <Link to="/">
            <Heading
              // {...title}
              // Placeholder until we can come up with a logo
              color="#fff"
              content="podstacks"
            />
          </Link>

          {/* <Logo
            href="/saasclassic"
            logoSrc={LogoImage}
            title="Portfolio"
            logoStyle={logoStyle}
            className="main-logo"
          />
          <Logo
            href="/saasclassic"
            logoSrc={LogoImageAlt}
            title="Portfolio"
            logoStyle={logoStyle}
            className="logo-alt"
          /> */}
          <Box {...menuWrapper}>
            <ScrollSpyMenu
              className="main_menu"
              menuItems={Data.saasClassicJson.USER_MENU_ITEMS}
              offset={-70}
            />
            {/* <a href="/pod2020" className="navbar_button">
              <Button {...buttonStyle} title="2020 SPECIAL OFFER" />
            </a> */}
            <Drawer
              width="420px"
              placement="right"
              drawerHandler={<HamburgMenu barColor="#fff" />}
              open={state.isOpen}
              toggleHandler={toggleHandler}
            >
              <ScrollSpyMenu
                className="mobile_menu"
                menuItems={Data.saasClassicJson.USER_MENU_ITEMS}
                drawerClose={true}
                offset={-100}
              />
              <a href="#1" className="navbar_drawer_button">
                <Button {...buttonStyle} title="GET STARTED" />
              </a>
            </Drawer>
          </Box>
        </Box>
      </Container>
    </NavbarWrapper>
  );
};

// Navbar.propTypes = {
//   navbarStyle: PropTypes.object,
//   logoStyle: PropTypes.object,
//   button: PropTypes.object,
//   row: PropTypes.object,
//   menuWrapper: PropTypes.object,
// };


export default UsersNavbar;
